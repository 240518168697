<template>

    <!--    <el-table-->
    <!--            :data="caseData"-->
    <!--            style="width: 100%"-->
    <!--    >-->
    <!--        <el-table-column-->
    <!--                prop = "html"-->
    <!--                label="Задание">-->

    <!--        </el-table-column>-->
    <!--    </el-table>-->

    <div id="CaseText"  v-html="html"></div>
    <div id="CaseLevel"></div>
</template>

<script>
    export default {
        name: 'previewCase',
        props: {
            case: {}
        },
        data() {
            return {
                caseData: [],
                html: ''
            }
        },
        mounted() {
            this.caseData = [this.case]
            let text = this.case.html;
            text = text.replace("<h1>", "<p>")
            text = text.replace("</h1>", "</p>")
            text = text.replace("<h2>", "<p>")
            text = text.replace("</h2>", "</p>")
            text = text.replace("<br>", "")
            text = text.replace("<p></p>", "")
            this.html = text
            //document.getElementById('CaseText').innerHTML = text
            // document.getElementById('CaseLevel').innerHTML = "Уровень сложности: " + this.case.difficulty

        }
    }
</script>

<style scoped>

</style>
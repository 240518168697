<template>
  <div v-loading.fullscreen.lock="loading">
    <el-row style="padding: 16px">
      <!--                колонка добавления-->
      <el-col :span="6">
        <el-row>
          <el-card style="width: 100%">
            <el-row class="tac" style="text-align: left">
              <el-col>
                <a
                  style="
                    margin-left: 16px;
                    margin-bottom: 16px;
                    align-items: center;
                    display: flex;
                  "
                >
                  <el-button
                    type="success"
                    @click="
                      $router.push({
                        name: newCaseLink,
                        params: { component: 'caseConstructor' },
                      })
                    "
                    circle
                    ><i class="fas fa-plus fa-2x"></i
                  ></el-button>
                  <div style="margin-left: 16px">
                    <el-row style="text-align: start">
                      <div class="title" style="font-size: 16px">{{ $t('Добавить') }}</div>
                    </el-row>
                  </div>
                </a>
                <el-menu
                  default-active="1"
                  class="el-menu-vertical"
                  @select="profileMenuSelect"
                >
                  <el-menu-item index="1">
                    <i class="el-icon-s-claim"></i>
                    <span>{{ $t('Текущие кейсы') }}</span>
                  </el-menu-item>
                  <el-menu-item index="2">
                    <i class="el-icon-s-management"></i>
                    <span> {{ $t("Архив") }}</span>
                  </el-menu-item>
                </el-menu>
              </el-col>
            </el-row>
          </el-card>
        </el-row>
      </el-col>
      <!--                колонка списка кейсов-->
      <el-col :span="18" style="padding-left: 16px">
        <el-card style="width: 100%">
          <template #header>
            <div v-if="menuIndex === '1'" style="text-align: left">
              {{ $t('Текущие кейсы') }}
            </div>
            <div v-else-if="menuIndex === '2'" style="text-align: left">
              {{ $t("Архив") }}
            </div>
            <div v-else-if="menuIndex === '3'" style="text-align: left">
              {{ $t('Новый кейс') }}
            </div>
          </template>
          <el-row v-if="menuIndex === '1'">
            <el-table
              :data="newCases"
              style="width: 100%"
              @expand-change="expandChange"
            >
              <el-table-column type="expand">
                <template #default="props">
                  <previewCase
                    :ref="props.row.name"
                    v-if="props.row.expand"
                    :case="props.row"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('Название')"> </el-table-column>
              <el-table-column prop="date" :label="$t('Дата изменения')">
              </el-table-column>
              <el-table-column :label="$t('Управление')" width="300">
                <template #default="props">
                  <el-button
                    v-if="!props.row.status"
                    type="success"
                    size="small"
                    @click="updateCaseStatus(props.row)"
                  >
                    <i class="fa fa-play fa-lg"></i>
                  </el-button>
                  <el-button
                    v-else
                    type="success"
                    size="small"
                    @click="updateCaseStatus(props.row)"
                  >
                    <i class="fa fa-pause fa-lg"></i>
                  </el-button>

                  <el-button
                    type="danger"
                    size="small"
                    @click="deleteCase(props.row)"
                  >
                    <i class="fas fa-trash fa-lg"></i>
                  </el-button>

                  <el-button
                    type="warning"
                    size="small"
                    @click="editCase(props.row)"
                  >
                    <i class="fas fa-pen-square fa-lg"></i>
                  </el-button>
                  <el-button
                    type="primary"
                    size="small"
                    @click="duplicateCase(props.row)"
                  >
                    <i class="fas fa-copy fa-lg"></i>
                  </el-button>
                  <el-button
                    type="info"
                    size="small"
                    @click="updateCaseCurrent(props.row)"
                  >
                    <i class="fas fa-file-archive fa-lg"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-row v-else-if="menuIndex === '2'">
            <el-table
              :data="oldCases"
              style="width: 100%"
              @expand-change="expandChange"
            >
              <el-table-column type="expand">
                <template #default="props">
                  <previewCase v-if="props.row.expand" :case="props.row" />
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('Название')"> </el-table-column>
              <el-table-column prop="date" :label="$t('Дата изменения')">
              </el-table-column>
              <el-table-column fixed="right" :label="$t('Управление')" width="300">
                <template #default="props">
                  <el-button
                    type="danger"
                    size="small"
                    @click="deleteCase(props.row)"
                  >
                    <i class="fas fa-trash fa-lg"></i>
                  </el-button>

                  <el-button
                    type="info"
                    size="small"
                    @click="updateCaseCurrent(props.row)"
                  >
                    <i class="fas fa-redo fa-lg"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <el-row v-else-if="menuIndex === '3'">
            <el-col style="height: 100%">
              <vue-editor style="width: 100%" v-model="content" />
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CaseController from "../../controllers/case.controller";
import previewCase from "./previewCase";
import router from "../../router";
import { ElMessage } from "element-plus";

export default {
  name: "Cases",
  components: {
    previewCase,
  },
  data() {
    return {
      content: "",
      // height: Math.max(
      //     document.documentElement.clientHeight,
      //     window.innerHeight || 0
      // ),
      menuIndex: "1",
      newCases: [],
      oldCases: [],
      newCaseLink: "newCase",
      editCaseLink: "editCase",
      duplicateCaseLink: "duplicateCase",
      loading: false,
      CaseController: null,
    };
  },
  computed: {},
  mounted() {
    this.CaseController = new CaseController();
    const vm = this;
    let editOn = true;
    this.CaseController.loadAllCases().then((response) => {
      for (let Case of response.data) {
        if (Case.chatLink === "") {
          editOn = false;
        }

        console.log("data", response.data);

        let date = ''        
        if (Case.date) {
          let dt = new Date(Case.date);
          const locale = 'ru-RU';
          date = !isNaN(dt) ? `${dt.toLocaleDateString(locale)} ${dt.toLocaleTimeString(locale)}` : '';          
          //date = "Дата: " + dt.getDate() + "." + (dt.getMonth() + 1) + "." + dt.getFullYear() + "  Время: " + dt.getHours() + ":" + dt.getMinutes();
        }

        let loadedCase = {
          aims: Case.aims, // список ID узлов
          name: Case.name, // имя кейсы
          difficulty: Case.difficulty, // сложность 1-5, число
          spread_: Case.spread_, // распространенность 1-5, число
          duration: Case.duration + " минут", // длительность в минутах, число
          date: date, // дата создания,
          status: Case.status, //запущена ли игра
          caseId: Case._id,
          ontologyId: Case.ontologyId,
          chatLink: Case.chatLink,
          editOn: editOn,
          html: Case.html,
          isCurrent: Case.isCurrent,
        };

        if (Case.isCurrent) {
          vm.newCases.push(loadedCase);
        } else {
          vm.oldCases.push(loadedCase);
        }

      }
    });
  },
  methods: {
    expandChange(row) {
      row.expand = !row.expand;
    },
    editChatLink(row) {
      row.editOn = !row.editOn;
    },
    saveChatLink(row) {
      if (row.chatLink !== "") {
        row.editOn = !row.editOn;
      }
      this.CaseController.updateCase(row, "chatLink");
    },
    profileMenuSelect(index) {
      console.log(index);
      this.menuIndex = index;
    },
    updateCaseStatus(row) {
      row.status = !row.status;
      this.CaseController.updateCase(row, "status");
    },
    updateCaseCurrent(row) {
      if (!row.status) {
        const initialIsCurrent = row.isCurrent;
        const vm = this;

        row.isCurrent = !initialIsCurrent;

        vm.CaseController.updateCase(row, "isCurrent").then(() => {
          if (initialIsCurrent) {
            vm.newCases.splice(vm.newCases.indexOf(row), 1);
            vm.oldCases.push(row);
          } else {
            vm.oldCases.splice(vm.oldCases.indexOf(row), 1);
            vm.newCases.push(row);
          }
        });
      } else {
        ElMessage({
          offset: document.querySelector(".el-menu").clientHeight + 20,
          duration: 1000,
          message: this.$t("Остановите игру перед тем, как отправлять кейс в архив"),
          type: "warning",
        });
      }
    },
    deleteCase(row) {
      const vm = this;

      if (!row.status) {
        vm.CaseController.deleteCase(row.caseId).then(() => {
          if (row.isCurrent) {
            vm.newCases.splice(vm.newCases.indexOf(row), 1);
          } else {
            vm.oldCases.splice(vm.newCases.indexOf(row), 1);
          }
        });
      } else {
        ElMessage({
          offset: document.querySelector(".el-menu").clientHeight + 20,
          duration: 1000,
          message: this.$t("Остановите игру перед тем, как удалять кейс"),
          type: "warning",
        });
      }
    },
    editCase(row) {
      if (!row.status) {
        router.push({ path: `/${this.editCaseLink}/${row.caseId}` });
      } else {
        ElMessage({
          offset: document.querySelector(".el-menu").clientHeight + 20,
          duration: 1000,
          // appendTo: document.querySelector('.el-row'),
          message: this.$t("Остановите игру перед тем, как редактировать кейс"),
          type: "warning",
        });
      }
    },
    duplicateCase(row) {
      router.push({ path: `/${this.duplicateCaseLink}/${row.caseId}` });
    },
  },
};
</script>

<style scoped>
.el-table-fit .overflowAvatar {
  opacity: 0.3;
}
</style>
